import { theme as baseTheme, extendTheme } from '@chakra-ui/react'

import { Button } from './button'
import { Container } from './container'
import { Heading } from './heading'
// import Input from './input'
import { Link } from './link'
import { Text } from './text'

// Breakpoints
// https://chakra-ui.com/docs/features/responsive-styles
// Chakra breakpoints are:
// sm: "30em" = 480px
// md: "48em" = 768px
// lg: "62em" = 992px
// xl: "80em" = 1280px
// "2xl": "96em" = 1536px

// Making sure chakra base sizes are not present
// eslint-disable-next-line @typescript-eslint/no-explicit-any
baseTheme.components.Heading.sizes = undefined as any

export const theme = extendTheme({
  components: {
    Heading,
    Text,
    Button,
    Container,
    Link,
  },
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
      'html, body': {
        color: 'black',
      },
    },
  },
  shadows: {
    md: '0px 0px 16px rgba(0, 0, 0, 0.08)',
  },
  fonts: {
    heading: `Clother-Regular, ${baseTheme.fonts.heading}`,
    body: `Clother-Regular, ${baseTheme.fonts.body}`,
  },
  // Thes font size should not be used
  // Use the component's sizes instead
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
  },
  lineHeights: {
    md: '1,4rem',
  },
  colors: {
    blue: '#00B4D5',
    active: '#0F778A',
    disabled: '#BDE5EC',
    blue_soft: '#EFFAFC',
    green: '#A8C455',
    green_soft: '#F5F9EC',
    orange: '#EA8C42',
    orange_soft: '#FDF5EE',
    yellow: '#F1B748',
    yellow_soft: '#FCF1D5',
    white: '#fff',
    miimosa: '#003F2C',
    black: '#000',
    black_text: '#1D1D1B',
    radio: {
      500: '#00B4D5',
    },
    checkbox: {
      500: '#00B4D5',
    },
    input: {
      500: '#00B4D5',
    },
  },
})
