import { Flex, Text, Link, BoxProps, Image as ChakraImage } from '@chakra-ui/react'

import { Image } from '@miimosa/design-system'

interface Props {
  side: 'left' | 'right'
  bgColor: string
  logo: string
  description: string
  link?: string
  linkText?: string
  testimonyText: string
  signText: string
  image: string
  color: string
}

const RoundedRectangle = ({
  side,
  bgColor,
  logo,
  description,
  link,
  linkText,
  testimonyText,
  signText,
  image,
  color,
  children,
  ...props
}: Props & BoxProps) => {
  const alignItems = side === 'left' ? 'flex-start' : 'flex-end'
  const borderRadius = side === 'left' ? '0px 500px 500px 0px' : '500px 0px 0px 500px'

  return (
    <Flex h="auto" w="full" direction="column" alignItems={alignItems} {...props}>
      {children}
      <Flex
        h="auto"
        w={{ base: 'full', lg: '90%' }}
        bgColor={bgColor}
        direction="column"
        borderRadius={{ base: 0, lg: borderRadius }}
        py={12}
      >
        <Flex
          direction="column"
          pl={{ base: 0, lg: side === 'left' ? '258px' : '0px' }}
          pr={{ base: 0, lg: side === 'right' ? '258px' : '0px' }}
          alignItems={alignItems}
          mb={18}
        >
          <Flex w="full" direction="column" alignItems={alignItems} mb={18} px={{ base: 8, lg: 0 }}>
            <Flex w="full" pl={{ md: side === 'right' ? '220px' : '0px' }} mb={8}>
              <ChakraImage alt="" src={logo} w="208px" h="105px" />
            </Flex>
            <Text maxW={{ base: 'full', lg: '800px' }} size="md">
              {description}
            </Text>
            {link && linkText && (
              <Link size="md" target="_blank" color={color} href={link}>
                {linkText}
              </Link>
            )}
          </Flex>
          <Flex
            direction={{ base: 'column', lg: side === 'left' ? 'row' : 'row-reverse' }}
            alignItems="center"
            columnGap={8}
            px={{ base: 8, lg: 0 }}
          >
            <Flex direction="column" maxW={{ base: 'full', lg: '736px' }} mb={{ base: 4, lg: 0 }}>
              <Text mb={3} size="md" textAlign={side === 'right' ? 'left' : 'right'}>
                {testimonyText}
              </Text>
              <Text color={color} align="right" size="md">
                {signText}
              </Text>
            </Flex>
            <Image
              aspectRatio={1}
              alt=""
              src={image}
              style={{ objectFit: 'cover' }}
              fill
              borderRadius="1000px"
              overflow="hidden"
              w={{ base: '200px', md: '352px' }}
              flex="0 0 auto"
              ml={side === 'right' ? '-50px' : undefined}
              mr={side === 'left' ? '-50px' : undefined}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default RoundedRectangle
