import { Flex, Text, Container, Link as ExternalLink } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Image, Icon, Link } from '@miimosa/design-system'
import {
  LogoDanielNina,
  Logo2,
  Facebook,
  LinkedIn,
  Twitter,
  LogoMakesense,
} from '@miimosa/design-system/components/icons'

const Footer = () => {
  return (
    <footer>
      <Container variant="full" position="relative">
        <Flex position="absolute" bottom="0" left="0" width="100%" height="536px" zIndex={0}>
          <Image alt="" src={s3ImageURL('bg_footer_carasso.svg')} fill style={{ objectFit: 'cover' }} />
        </Flex>
        <Flex
          direction="column"
          pt={{ base: 10, md: 16 }}
          pb={{ base: 10, md: 0 }}
          width="100%"
          px={{ base: '22px', md: '160px' }}
          zIndex={1}
        >
          <Icon name="LogoCarasso" size="xl" />
          <Flex direction="column">
            <Text size="3xs" mb="3">
              Un programme porté par
            </Text>
            <Flex align="center" width="100%" columnGap={{ base: 2, md: 12 }} flexWrap="wrap" rowGap={6}>
              <ExternalLink href="https://www.fondationcarasso.org/" target="_blank">
                <LogoDanielNina width="93px" height="47px" />
              </ExternalLink>
              <ExternalLink href="https://miimosa.com" target="_blank">
                <Logo2 width="80px" height="13px" color="miimosa" />
              </ExternalLink>
              <ExternalLink href="https://france.makesense.org/" target="_blank">
                <LogoMakesense as="a" width="122px" height="33px" />
              </ExternalLink>
              <Link href="/manifest" size="2xs">
                Le programme Tiina
              </Link>
              <Link href="/projects" size="2xs">
                Découvrir les projets
              </Link>
              <Link href="/candidater" size="2xs">
                Lancer mon projet
              </Link>
              <Link href="/faq" size="2xs">
                FAQ
              </Link>
            </Flex>
            <Text size="3xs" mt="36px">
              © 2022 MiiMOSA
            </Text>
            <Text size="3xs" width={{ base: '100%', md: '360px' }}>
              MiiMOSA est une plateforme de financement participatif régulée par les autorités françaises.
              Immatriculation ORIAS N°17003251.
            </Text>
            <Flex
              mt={{ base: '30px', md: '120px' }}
              align="center"
              columnGap={6}
              justify="center"
              mb={{ base: 2, md: '8' }}
            >
              <ExternalLink href="https://www.facebook.com/profile.php?id=100069382953837">
                <Facebook color="#2A2A2A" />
              </ExternalLink>
              <ExternalLink href="https://www.linkedin.com/company/fondation-daniel-et-nina-carasso?originalSubdomain=fr">
                <LinkedIn color="#2A2A2A" />
              </ExternalLink>
              <ExternalLink href="https://twitter.com/fdnc_fr?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">
                <Twitter color="#2A2A2A" />
              </ExternalLink>
            </Flex>
          </Flex>
        </Flex>
      </Container>
      <Flex w="100%" align="center" justify="center" height="44px" bgColor="blue">
        <Text size="3xs" color="white">
          Fait avec ♥ par MiiMOSA | Copyright © 2022 MiiMOSA
        </Text>
      </Flex>
    </footer>
  )
}

export default Footer
