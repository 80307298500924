import { SystemStyleObject } from '@chakra-ui/react'

export const sizes: Record<string, SystemStyleObject> = {
  '3xs': {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
  '2xs': {
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1rem',
  },
  xs: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  sm: {
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '1.25rem',
  },
  md: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  lg: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.5rem',
  },
  xl: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
  '2xl': {
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '1.75rem',
  },
}

export const Text = {
  sizes,
  // Outdated, use the color attribute directly instead
  variants: {
    emphasis: {
      textTransform: 'uppercase',
      fontWeight: '500',
      fontSize: '0.625rem',
      lineHeight: '1rem',
    },
  },
  // The default size and variant values
  defaultProps: {
    fontWeight: '400',
    size: 'md',
    color: 'black',
  },
}
