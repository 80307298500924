import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useDisclosure,
  Text,
} from '@chakra-ui/react'
import NextLink from 'next/link'

import { LogoCarasso } from '@miimosa/design-system/components'
import Burger from '@miimosa/design-system/components/icons/Burger'
import { useMountedBreakpoints } from '@miimosa/design-system/lib/hooks'

import MobileNav from './MobileNav'

const Header = () => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const isSmall = useMountedBreakpoints({ base: true, lg: false }, false)

  return (
    <Box
      id="top"
      bgColor="white"
      position="sticky"
      top="0"
      left="0"
      right="0"
      zIndex="1000"
      as="nav"
      transition="background 0.1s ease-out"
      borderBottom="1px solid #BDE5EC"
    >
      <Flex
        height="71px"
        justifyContent={{ base: 'space-between', md: 'inherit' }}
        px={{ base: 0, md: '160px' }}
        alignItems="center"
        position="relative"
      >
        <Box
          mx={{ base: 4, lg: 0 }}
          _focus={{
            outline: 0,
            boxShadow: 'none',
          }}
        >
          <NextLink href={'/'} passHref>
            <LogoCarasso h="auto" w="86px" />
          </NextLink>
        </Box>
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          justify="flex-end"
          position={{ lg: 'relative' }}
          mr={{ lg: 4 }}
        >
          {!isSmall && (
            <Flex align="center">
              <NextLink href="/manifest" passHref>
                <Text size="lg" cursor="pointer">
                  Le programme Tiina
                </Text>
              </NextLink>
              <NextLink href="/projects" passHref>
                <Text ml="10" size="lg" cursor="pointer">
                  Découvrir les projets
                </Text>
              </NextLink>
              <NextLink href="/candidater" passHref>
                <Button size="sm" ml="10" variant="primary">
                  <Text size="lg">Candidater</Text>
                </Button>
              </NextLink>
            </Flex>
          )}

          {isSmall && (
            <Button
              display={{ base: 'block', md: 'none' }}
              onClick={onToggle}
              aria-label={'Toggle Navigation'}
              variant="ghost"
              size="iconMD"
            >
              <Burger isOpen={isOpen} color="blue" />
            </Button>
          )}

          <Box
            h="2px"
            bg="primary_grey.DEFAULT"
            bottom="0"
            left="0"
            w="100%"
            position="absolute"
            transition="opacity 0.1s ease-out"
            display={{ base: 'none', md: 'block' }}
          />
          {isSmall && (
            <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="full">
              <DrawerOverlay marginTop="71px" />
              <DrawerContent marginTop="71px" maxHeight={`calc(100% - ${71})`}>
                <DrawerBody padding="0" borderTop="2px solid" borderColor="primary_grey.DEFAULT">
                  <MobileNav onToggle={onToggle} />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          )}
        </Flex>
      </Flex>
      <Flex justifyContent="center" position="absolute" top="calc(100% + 4px)" width="100%"></Flex>
    </Box>
  )
}

export default Header
