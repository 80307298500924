import { Heading, Flex, Text, Button } from '@chakra-ui/react'
import NextLink from 'next/link'

const MobileNav = ({ onToggle }: { onToggle: () => void }) => {
  return (
    <Flex direction="column" ml={4}>
      <NextLink href="/manifest" passHref>
        <Heading size="sm" mt={3} cursor="pointer" onClick={() => onToggle()}>
          Le programme Tiina
        </Heading>
      </NextLink>
      <NextLink href="/projects" passHref>
        <Heading size="sm" my={3} cursor="pointer" onClick={() => onToggle()}>
          Découvrir les projets
        </Heading>
      </NextLink>
      <NextLink href="/candidater" passHref>
        <Button size="sm" variant="primary" mt={3} onClick={() => onToggle()}>
          <Text size="lg">Candidater</Text>
        </Button>
      </NextLink>
    </Flex>
  )
}

export default MobileNav
