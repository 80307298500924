import { Button, Divider, Flex, Text } from '@chakra-ui/react'
import { StylesConfig } from 'react-select'

import { Refresh } from '@miimosa/design-system/components/icons'
import Select from '@miimosa/design-system/components/Select'

import { CarassoCategory, CarassoImpact } from '../../../../../packages/directus/src/types'

type TUseReactSelectStyles = () => StylesConfig<unknown, boolean>

const Filters = ({
  categories,
  selectedCategory,
  onCategoryChange,
  impacts,
  selectedImpact,
  onImpactChange,
  regions,
  selectedRegion,
  onRegionChange,
}: {
  categories: CarassoCategory[]
  selectedCategory: CarassoCategory | null | undefined
  onCategoryChange: (cat: CarassoCategory, isReset: boolean) => void
  impacts: CarassoImpact[]
  selectedImpact: CarassoImpact | null | undefined
  onImpactChange: (impact: CarassoImpact, isReset: boolean) => void
  regions: { code: string; name: string }[]
  selectedRegion: { code: string; name: string } | null | undefined
  onRegionChange: (region: { code: string; name: string }, isReset: boolean) => void
}) => {
  const refreshFilters = () => {
    onCategoryChange(categories[0], true)
    onImpactChange(impacts[0], true)
    onRegionChange(regions[0], true)
  }

  const customStyles = {
    control: (style: TUseReactSelectStyles) => ({
      ...style,
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: '20px',
      borderWidth: 0,
      borderRadius: 0,
      boxShadow: 'none',
    }),
    menu: (style: TUseReactSelectStyles) => ({
      ...style,
      borderRadius: 0,
      padding: 0,
      margin: 0,
      boxShadow: 'none',
    }),
    input: (style: TUseReactSelectStyles) => ({
      ...style,
      paddingLeft: 0,
      paddingRight: 0,
    }),
    option: (style: TUseReactSelectStyles, { isSelected }: { isSelected: boolean }) => ({
      ...style,
      color: isSelected ? '#00B4D5' : '#2A2A2A',
      fontWeight: isSelected ? 'bold' : '400',
      padding: '4px 14px 4px 8px',
      textAlign: 'left',
      marginLeft: '6px',
      marginRight: '6px',
      marginBottom: '1px',
      backgroundColor: 'transparent',
      '&:hover': {
        cursor: 'pointer',
        color: 'hover',
        backgroundColor: 'transparent',
      },
      label: {
        cursor: 'pointer',
      },
    }),
    valueContainer: (style: TUseReactSelectStyles) => ({
      ...style,
      padding: 0,
      fontWeight: 700,
    }),
    menuList: (style: TUseReactSelectStyles) => ({
      ...style,
      background: 'white',
      boxShadow: 'none',
    }),
    singleValue: (style: TUseReactSelectStyles) => ({
      ...style,
      color: '#00B4D5',
      cursor: 'pointer',
    }),
  }

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      w="full"
      bg="white"
      pl="24px"
      py="16px"
      height={{ base: 'auto', md: '73px' }}
      justifyContent="space-between"
      border="solid 1px #1D1D1B"
      borderRadius="4px"
    >
      <Flex direction={{ base: 'column', md: 'row' }} w="95%" justifyContent="space-evenly" align="center">
        <Flex direction="column" w="full">
          <Text size="xs" color="black">
            Catégories
          </Text>
          <Select
            name="categories"
            customStyles={customStyles}
            data={categories}
            getOptionLabel={(categories: { title: string }) => categories.title}
            getOptionValue={(categories: { slug: string }) => categories.slug}
            value={selectedCategory}
            defaultSelectValue={categories[0]}
            onChange={(v: any) => {
              onCategoryChange(v, false)
            }}
            placeholder={categories[0].title}
          />
        </Flex>
        <Divider orientation="vertical" w="1px" bg="#C4C4C4" mx="24px" />
        <Flex direction="column" w="full">
          <Text size="xs" color="black">
            Finalité impacts
          </Text>
          <Select
            name="impacts"
            customStyles={customStyles}
            data={impacts}
            getOptionLabel={(reward_options: { title: string }) => reward_options.title}
            getOptionValue={(reward_options: { slug: string }) => reward_options.slug}
            value={selectedImpact}
            defaultSelectValue={impacts[0]}
            onChange={(v: any) => onImpactChange(v, false)}
            placeholder={impacts[0].title}
          />
        </Flex>
        <Divider orientation="vertical" w="1px" bg="#C4C4C4" mx="24px" />
        <Flex direction="column" w="full">
          <Text size="xs" color="black">
            Lieu
          </Text>
          <Select
            name="regions"
            customStyles={customStyles}
            data={regions}
            getOptionLabel={(regions: { name: string }) => regions.name}
            getOptionValue={(regions: { code: string }) => regions.code}
            value={selectedRegion}
            defaultSelectValue={regions[0]}
            onChange={(v: any) => onRegionChange(v, false)}
            placeholder={regions[0].name}
          />
        </Flex>
        <Divider orientation="vertical" w="1px" bg="#C4C4C4" ml="24px" />
      </Flex>
      <Flex
        display={{ base: 'none', md: 'flex' }}
        h="full"
        alignItems="center"
        w="5%"
        justifyContent="center"
        onClick={() => refreshFilters()}
        _hover={{
          cursor: 'pointer',
        }}
      >
        <Refresh h="20px" w="20px" />
      </Flex>
      <Flex
        display={{ base: 'flex', md: 'none' }}
        h="full"
        alignItems="center"
        w={{ base: '100%', md: '5%' }}
        justifyContent="center"
      >
        <Button variant="secondary" size="md" onClick={() => refreshFilters()}>
          Réinitialiser
        </Button>
      </Flex>
    </Flex>
  )
}
export default Filters
