import Head from 'next/head'
import { ReactElement } from 'react'

import Footer from '../Footer'
import Header from '../Header'

const Layout = ({ children }: { children: ReactElement }) => {
  return (
    <>
      <Head>
        <meta name="description" content="Carasso" />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" href="/favicon.png" sizes="64x64" />
      </Head>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
