import { Avatar, Flex, Image, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import { default as NumberFormat } from 'react-number-format'

import { Icon } from '@miimosa/design-system'
import { CarassoImpact } from '@miimosa/directus'

interface Props {
  slug: string
  image: string
  title: string
  place: string
  impactData: { carasso_impacts_id: CarassoImpact }[]
  description: string
  avatar: string
  username: string
  dotationAmount: number
}

const ProjectCard = ({
  image,
  title,
  place,
  impactData,
  description,
  avatar,
  username,
  dotationAmount,
  slug,
}: Props) => {
  return (
    <NextLink href={`/projects/${slug}`} passHref>
      <Flex
        w={{ base: '100%', md: '352px' }}
        h="565px"
        direction="column"
        border="1px solid"
        borderColor="black_text"
        borderRadius="4px"
        cursor="pointer"
      >
        <Flex height="225px" borderTopLeftRadius="3px" borderTopRightRadius="3px">
          <Image
            alt="project image"
            src={image}
            width="full"
            height="225px"
            objectFit="cover"
            borderTopLeftRadius="3px"
            borderTopRightRadius="3px"
          />
        </Flex>
        <Flex direction="column" px={6} py={5} flex="1 1 0">
          <Flex direction="column" flex="1 1 0">
            <Text size="2xl" height="56px">
              {title}
            </Text>
            <Flex direction="row" alignItems="center" columnGap={2} mt={2}>
              <Icon name="PinMap" size="5xs" />
              <Text size="3xs">{place}</Text>
            </Flex>
            <Flex direction="row" alignItems="center" columnGap={2} mt={3}>
              <Icon name="ColoredDiamond" size="4xs" />
              <Text size="lg" color="blue">
                <NumberFormat
                  value={dotationAmount}
                  displayType={'text'}
                  decimalScale={0}
                  thousandSeparator={' '}
                  suffix={' € financé par Tiina'}
                />
              </Text>
            </Flex>
            <Flex direction="row" mt={3} rowGap={2} columnGap={4} flexWrap="wrap">
              {impactData &&
                impactData.map(({ carasso_impacts_id: { color, title } }, i) => (
                  <Flex key={`impact_${i}`} bg={color} px={3} py={1} align="center" direction="row" borderRadius="20px">
                    <Text size="3xs" fontSize="0.7rem" color="white">
                      {title}
                    </Text>
                  </Flex>
                ))}
            </Flex>
            <Text size="xs" mt={3} mb={1} flex="1 1 0" overflow="hidden">
              {description}
            </Text>
          </Flex>
          <Flex direction="row" justifyContent="flex-end" alignItems="center">
            <Avatar height="24px" width="24px" color="black" name={username} src={avatar ?? undefined} mr="4px" />
            <Text size="3xs"> par {username}</Text>
          </Flex>
        </Flex>
      </Flex>
    </NextLink>
  )
}

export default ProjectCard
